import React from "react"
import { withGesture } from 'react-with-gesture'
import { Spring } from "react-spring/renderprops";
import "./Slider.css"


function Slider({
    index,
    offsetRadius,
    gameName,
    content,
    gamePoster,
    platform,
    link,
    moveSlide,
    delta,
    down,
    animationConfig,
    translateY,
    translateYoffset,
    distanceFactor,
    offsetFromMiddle
}){   
    offsetFromMiddle = index - offsetRadius;
    distanceFactor = 1 - Math.abs(offsetFromMiddle / (offsetRadius + 1));

    translateYoffset =
    50 * (Math.abs(offsetFromMiddle) / (offsetRadius + 1));
    translateY = 0;

    if(offsetFromMiddle === 0 && down)
    {
        translateY += delta[1] / (offsetRadius + 1);

        if(translateY < -63 )
        {
            moveSlide(1);
        }
        if(translateY > 63)
        {
            moveSlide(-1);
        }
            
      }

    if (offsetFromMiddle > 0) {
        translateY -= translateYoffset;
    } else if (offsetFromMiddle < 0) {
        translateY += translateYoffset;
    }

    return(
        
        <Spring
            to={{
                transform:`translate(-50%,  ${-50 + translateY}%) scale(${distanceFactor})`,
                top: `${offsetRadius === 0 ? 50 : 50 + (offsetFromMiddle * 50) / offsetRadius
                    }%`,
                opacity: distanceFactor * distanceFactor,
                zIndex: Math.abs(Math.abs(offsetFromMiddle) - 2)
            }}
            config={animationConfig}
        >
            {(style) => (
                <div className="SliderContainer" 
                    style={{...style, zIndex: Math.abs(Math.abs(offsetFromMiddle) - 2)}}>
                    <div className="SliderCard" style={{}}>
                        <div className="grid-container">
                            <div className="GamePoster">
                                <img src={gamePoster} alt="게임포스터"></img>
                            </div>
                            <div className="Content">
                                <p className="context">{content}</p>
                            </div>
                            <div className="GameName">
                                <p className="gameName">{gameName}</p>
                            </div>
                            
                            <div className="Platform">
                                <a href={link} target="_blank" rel="noreferrer">
                                    <img src={platform === "Android" ? 'https://i.ibb.co/F0wTtFY/Android-Icon.png' : 'https://i.ibb.co/rkYRbH3/Desktop.png' } alt="플랫폼사진"></img>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Spring>
    );
}

export default withGesture()(Slider);