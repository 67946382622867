import React from "react"
import "./DotNav.css"

function DotNav({
    Count,
    index,
    moveIndex
}){

    var dots = [];

    for(var i = 0; i < Count; i++)
    {
        dots.push(i);
    }
    
    return (
        <ul className="DotNavContainer">
            {dots.map((dot, id)=>(
                <li key={id} onClick={() => moveIndex(dot)} 
                    style={{
                        transform: `translate(0%, ${Count / 2 > dot ? -(((Count / 2) - dot)  * 10) : (((Count / 2) - (Count - dot)) * 10)}%)`}}
                    className={index === dot ? "Dot_Active" : "Dot"}>

                </li>
            ))}
        </ul>
    );
}

export default DotNav;