import React from 'react'
import {Bar, HorizontalBar} from 'react-chartjs-2';
import $ from "jquery";
import './VoteResult.css'

const VoteResult = ({
    data,
    bar
}) => {
    const div = document.querySelector("#main");

    if($(div).width() <= 900)
    {
        bar = <Bar
        data={data}

        options={{
            maintainAspectRatio: false,
            scales: {
                xAxes: [{
                    ticks: {
                        fontFamily: "JS Dong Kang KR",
                        fontStyle: 'normal',
                        fontSize: 14,
                        fontColor: 'white'
                    },
                    gridLines: {
                        display: false
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: false,
                    },
                    ticks: {
                        display: false,
                        beginAtZero: true,
                    },
                }]
            },
        }}
    />
    }
    else{
        bar = <HorizontalBar
            data={data}

            options={{
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        ticks: {
                            beginAtZero: true,
                            display: false
                        },
                        gridLines: {
                            display: false
                        },
                    }],
                    yAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            fontFamily: "JS Dong Kang KR",
                            fontStyle: 'normal',
                            fontSize: 15,
                            fontColor: 'white'
                        },
                    }]
                },
            }}
        />
    }

    console.log();

    return <div className="VoteResultContainer">
        {bar}
    </div>
}

export default VoteResult;