import React from 'react';
import PropTypes from "prop-types";
import Slider from "./Silder"
import DotNav from "./DotNav"
import './VerticalCarosel.css'

function mod(a, b) {
    return ((a % b) + b) % b;
  }

class VerticalCarousel extends React.Component{
    state = {
        index: 0
        
    };

    static defaultProps = {
        offsetRadius: 1,
        animationConfig: { tension: 120, friction: 14, duration: 100 }
    };
    
    static propTypes = {
      gameInfo: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.any,
                gameName: PropTypes.string,
                content: PropTypes.string,
                link: PropTypes.string,
                platform: PropTypes.string
            })
        ).isRequired,
    };

    modBySlidesLength = (index) => {
        return mod(index, this.props.gameInfo.length);
      };

    clampOffsetRadius(offsetRadius) {
        const { gameInfo } = this.props;
        const upperBound = Math.floor((gameInfo.length - 1) / 2);
    
        if (offsetRadius < 0) {
          return 0;
        }
        if (offsetRadius > upperBound) {
          return upperBound;
        }
    
        return offsetRadius;
    }

    moveSlide = (direction) => {
        this.setState({
          index: this.modBySlidesLength(this.state.index + direction)
        });
    };

    moveIndex = (number) => {
      this.setState({
        index: number
      });
    }

    getSlidesCount()
    {
      const { gameInfo } = this.props;
      const presentableSlides = gameInfo;

      return presentableSlides.length;
    }

    getPresentableSlides() {
        const { gameInfo } = this.props;
        const { index } = this.state;
        let { offsetRadius } = this.props;
        offsetRadius = this.clampOffsetRadius(offsetRadius);
        const presentableSlides = [];

        for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {

            presentableSlides.push(gameInfo[this.modBySlidesLength(index + i)]);
        }

        return presentableSlides;
      }


    render(){
      const {animationConfig, offsetRadius} = this.props;
        return(
            <React.Fragment>
              <div>
                  {this.getPresentableSlides().map((gameInfo, presentIndex) => (
                    <Slider
                      index={presentIndex}
                      offsetRadius={this.clampOffsetRadius(offsetRadius)}
                      key={"gameInfo" + gameInfo.key}
                      gameName={gameInfo.gameName}
                      content={gameInfo.content}
                      gamePoster={gameInfo.gamePoster}
                      platform={gameInfo.platform}
                      link={gameInfo.link}
                      moveSlide={this.moveSlide}
                      animationConfig={animationConfig}
                    >

                    </Slider>
                  ))}

                <div className="DotBackground">
                  <div className="DotIndicator">
                    <DotNav
                      Count={this.getSlidesCount()}
                     index={this.state.index}
                      moveIndex={this.moveIndex}
                    >
                    </DotNav>
                  </div>
                </div>
                
              </div>
            </React.Fragment>
        )
    }
}

export default VerticalCarousel;