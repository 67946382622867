import React, { Component } from "react";
import './Main.css'
import VerticalCarousel from "./VerticalCarousel";
import { config } from "react-spring";
import Topnav from "../Topnav/Topnav";
import Poll from "./Poll";
import GameInfo from "./GameInfo"

export default class Main extends Component {

    constructor(props){
        super(props);
        this.state = { 
            config : config.gentle,
            flipped: false,
        } 
    }

    movePage = () =>{

        if(this.state.flipped)
        {
            document.getElementById('context_1').style.display = 'none';
            document.getElementById('context_2').style.display = 'flex';
        }
        else
        {
            document.getElementById('context_1').style.display = 'flex';
            document.getElementById('context_2').style.display = 'none';
        }

        document.getElementById('topnav_Button').textContent = this.state.flipped ? '게임 정보 보기' : '투표하러 가기'
        this.setState({
            flipped: !this.state.flipped
        });
    }

    movePage = (pageName) =>{
        if(pageName !== null)
        {
            document.getElementById('topnav_Button').textContent = !this.state.flipped ? '게임 정보 보기' : '투표하러 가기'
            this.setState({
                flipped: pageName === 1 ? false : true 
            });
        }
        else
        {
            document.getElementById('topnav_Button').textContent = !this.state.flipped ? '게임 정보 보기' : '투표하러 가기'
            this.setState({
                flipped: !this.state.flipped
            });
        }
    }

    render()
    {
        return ( 
            <div id="Background" className="background">
                <Topnav
                    movePage={this.movePage}
                >
                </Topnav>

                <div id="main" className="main" >
                    <div id="context_1" className="GameInfo" 
                        style={this.state.flipped ? {display:"none"} : {display:"flex"}}>

                        <div>
                            <img className="LeftDeco" src="image/LeftDeco.svg" alt="왼쪽데코"></img>
                            <img className="RightDeco" src="image/RightDeco.svg" alt="오른쪽데코"></img>
                        </div>
                   

                        <VerticalCarousel
                            gameInfo={GameInfo.gameInfo}
                            animationConfig={config}
                        />
                     
                    </div>
                    <div id="context_2" className="Vote" 
                        style={this.state.flipped ? {display:"flex"} : {display:"none"}}>
                        <Poll>

                        </Poll>
                    </div>
                </div>
            </div>

        );

    }
  }