import React, {  useState, useEffect } from 'react';
import "./Topnav.css";

const Topnav = ({
  movePage
}) => {
  const [modal, setModal] = useState();
  const [className, setClassName] = useState("layer_popup");
  const div = document.querySelector("#main");

  const modalUp = () => {
    setModal(true);
  }
  const modalOff = () => {
    setModal(false);
  }
  useEffect(() => {
    if (modal)
      setClassName(className + ' on');
    else if (modal === false)
      setClassName('layer_popup');
  }, [modal])



  return (
    <nav className="topnav_background" >
      <div className="topnav">
        <a className="leftAtt" href="https://thecgawards.kro.kr">
          <img className="Title" src="https://i.ibb.co/PgvXNBc/Title.png" alt="타이틀"></img>
          <img className="Logo" src="https://i.ibb.co/vHsCWmY/logo.png" alt="로고"></img>
          </a>

        <div onClick={() => movePage(null)} className="Button">
          <p id="topnav_Button">투표하러 가기</p>
          <img style={{position:"absolute"}}src="https://i.ibb.co/Nn89gYh/Scroll-button.png" alt="이동이미지"></img>
          </div>
      
        <ul className="topnav_icon">
          <li><a href="https://www.youtube.com/channel/UCCM8oRwSn5KdywxiuLp-bfA" target="_blank" rel="noreferrer">
            <img className="Youtube" src="https://i.ibb.co/q0yMty0/Youtube-Icon.png" alt="유튜브아이콘"></img></a></li>
          <li><a href="https://www.instagram.com/kah_best_of_game/" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/J373CBQ/Insta-Icon.png" alt="인스타아이콘"></img></a></li>
          <li><a href="https://www.facebook.com/anigoGame" target="_blank" rel="noreferrer">
            <img src="https://i.ibb.co/7r6Xkx9/Facebook-Icon.png" alt="페이스북아이콘"></img></a></li>
        </ul>

        <ul className="topnav_icon_small">
          <li onClick={modalUp}>
            <img src="menu.svg" alt="메뉴아이콘"></img></li>
        </ul>

      </div>
      <div className={className}>
        <div className="layer">
          <div className="menu_bar">
            <ul className="menu_item">
              <li> 
                <a className="link" onClick={() => {movePage(1); modalOff();}}>게임 정보</a> 
              </li>
              <li> 
                <a className="link" onClick={() => {movePage(2); modalOff();}}>투표 하기</a> 
              </li>
              <li style={{paddingTop: '20px'}}> 
                <a href="https://www.youtube.com/channel/UCCM8oRwSn5KdywxiuLp-bfA" target="_blank" rel="noreferrer">
                  <img style={{ width: '50px' }} src="https://i.ibb.co/q0yMty0/Youtube-Icon.png" alt="유튜브아이콘"></img></a>
                <a href="https://www.instagram.com/kah_best_of_game/" target="_blank" rel="noreferrer">
                  <img src="https://i.ibb.co/J373CBQ/Insta-Icon.png" alt="인스타아이콘"></img></a>
                <a href="https://www.facebook.com/anigoGame" target="_blank" rel="noreferrer">
                  <img src="https://i.ibb.co/7r6Xkx9/Facebook-Icon.png" alt="페이스북아이콘"></img></a>
              </li>

              <li style={{paddingTop:'20px'}}>
                <p>만든 사람</p>
              </li>
              <li> <p>웹 개발: 20기 박영진</p> </li>
              <li style={{paddingTop:"10px", paddingBottom:"5px"}}> <p>디자인</p> </li>
              <li> <p>20기 이지민</p> </li>
              <li style={{paddingBottom:"10px"}}> <p>20기 박유신</p> </li>
              <li> <p>메뉴 제작: 고한혁</p> </li>
              <li> <p style={{paddingTop:"40px"}}>이메일: yungjin702@gmail.com</p> </li>
            </ul>
           
          </div>
          <div className="btn_area">
          </div>
          <button className="btn_close" onClick={modalOff}>CLOSE</button>
        </div>
        <div className="dimmed">
        </div>
      </div>
    </nav>

  );
}




export default Topnav;
