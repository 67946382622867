import React, { useState, useEffect } from 'react';
import "./Poll.css"
import {v4 as uuid} from 'uuid'
import {useCookies} from 'react-cookie';
import PollContent from './PollContent';
import AwardInfo from './AwardInfo.json'


function Poll({
    content
})
{
    const [id, setID] = useState("");

    const [cookies, setCookie, removeCookie] = useCookies(['uniqueId']);

    useEffect(()=>{        

        if(cookies.uniqueId !== undefined)
        {
            setID(cookies.uniqueId);
        }
        else{
            const newId = uuid();
            setID(newId);
            setCookie('uniqueId', newId);
        }
    },[])

    return(
        <div className="Poll">
            {AwardInfo.contents.map((content, index) => (
                <div className="PollContainer" key={"PollContainer" + index}>
                    
                    <PollContent
                        key={"PollContent" + index}
                        awardName={content.awardName}
                        awardInfo={content.awardInfo}
                        sheetNum={content.sheetNum}
                        id={id}
                        contents={content.content}
                    >
                    </PollContent>

                </div>
             ))}  
        </div>
    )
}




export default Poll;