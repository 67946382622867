import React from 'react';
import './PollContent.css'
import $ from "jquery";
import VoteResult from './VoteResult'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css';
import AwardInfo from './AwardInfo.json'

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 100
    },
    desktop: {
      breakpoint: { max: 3000, min: 900 },
      items: 3,
      partialVisibilityGutter: 100
    },
    tablet: {
      breakpoint: { max: 900, min: 600 },
      items: 2,
      partialVisibilityGutter: 100
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      partialVisibilityGutter: 100
    }
  };


class PollContent extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            awardName: '',
            sheetNum: 0,
            showVote: true,
            isExist: 0,
            id: '',
            contents: null,
            count: 0,
            half: 0,
        } 
    }

    componentDidMount = () => {

        this.setState({
            count: this.props.contents.length,
            half: this.props.count / 2,
        })

    };

    voteGame = (id, index)=> {

        alert("투표 시간이 종료되었습니다. 투표 결과를 확인해주세요")
        return;

        if(this.state.isExist === 0)
        {
            var returnValue = window.confirm('투표하시겠습니까?');

            if(returnValue === false)
                return;

            alert("투표 중입니다.");

            this.setState({
                isExist: 1
            })

            $.ajax({
                type: "GET",
                url: `https://script.google.com/macros/s/AKfycbwVuwa_4i617rg-Z8TssavFxz18_TGvCzV8h0g0j5lq1ACC3A8CP7gQCuErQnsbos_W/exec?sheetName=시트${this.props.sheetNum}`,
                data: {
                  "post_ID": id,
                  "choice": index
                },
                success: function(response){
                    alert("투표 완료");
                },
                fail: function (params) {
                    alert("새로고침 후 다시 시도해주세요");
                }
            });
        }
        else if(this.state.isExist === 1){
            alert("이미 투표하셨습니다");
        }
        else{
            alert("새로고침 후 다시 시도해주세요");
        }
    }

    setVoteResult = () => {

        const SOURCE = `https://spreadsheets.google.com/feeds/list/1rl0fCsvCnAg9uWTKzztFlFd3Q2E08-zjRAeCGCd-55s/${this.props.sheetNum}/public/full?alt=json`;
        const datas = [];
        const labels = [];

        fetch(SOURCE)
        .then(res => res.json())
        .then(
            (result) => {
                const Content = this.props.contents;

                for(var i = 0; i < Content.length; ++i)
                {
                    datas.push(result['feed']['entry'][0]['gsx$' + AwardInfo.voteList[this.props.sheetNum - 1].name[i]]['$t']);
                    
                    labels.push(Content[i].name);
                }

                this.setState({
                    voteResult:
                        <VoteResult
                            data={{
                                labels: labels,
                                datasets:[{
                                    label:'투표 수',
                                    data: datas,
                                    backgroundColor: 'rgba(224, 39, 19, 0.6)',
                                }]
                            }}
                        >
                        </VoteResult>
                })
            },
            // 주의: 컴포넌트의 실제 버그에서 발생하는 예외사항들을 넘기지 않도록 
            // 에러를 catch() 블록(block)에서 처리하기보다는 
            // 이 부분에서 처리하는 것이 중요합니다.
            (error) => {
                    
            }
        )
    }

    showVoteResult = () =>{

        if(this.state.showVote)
            this.setVoteResult();

        this.setState({
            showVote: !this.state.showVote,
            
        })
    }

    render()
    {
        return(
            <React.Fragment>
                <div className='Info'>
                    <a className="AwardName">{this.props.awardName}</a>
                    <button onClick={() => this.showVoteResult()} className='PollState'></button>
                    <a className="AwardInfo">{this.props.awardInfo}</a>
                </div>

                <div className='voteSelect' style={this.state.showVote === true ?  {display:"flex"}: {display: 'none'}}>
                    <Carousel itemClass='carousel-item' containerClass='carousel-container' responsive={responsive} >
                        {this.props.contents.map((content, index) => (
                            <div className="PollContent" key={index}>
                                <div>
                                    <a>{content.name}</a>
                                    <img src={content.image} alt="후보포스터"></img>
                                    <button type="button" onClick={() => this.voteGame(this.props.id, content.index)}></button>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                
                <div className="VoteResult" style={this.state.showVote === true ?  {display:"none"}: {display: 'flex'}}>
                    {this.state.voteResult}
                </div>

                <div className="TopLine">
                    <img src="image/vote_Line.svg" alt="위쪽 라인"></img>
                </div>

                <div className="BottomLine">
                    <img src="image/vote_Line.svg" alt="아래쪽 라인"></img>
                </div>
            </React.Fragment>
        )
    }
}

export default PollContent;